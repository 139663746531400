.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  border-bottom: 1px solid #ddd;
  font-family: Arial, sans-serif;
}

* { font-family: arial !important }

.logo img {
  width: 100px;	
}

.navigation ul {
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;	
}

.navigation ul li a, .navigation ul li button {
  text-decoration: none;
  color: #333;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-family: Arial, sans-serif;	
}

.navigation ul li a:hover, .navigation ul li button:hover {
  color: #007bff;
}

.navigation ul li.active a {
  color: #7C3AF7;
  font-weight: bold;
  font-family: Arial, sans-serif;	
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navigation {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: white;
    border-top: 1px solid #ddd;
  }

  .navigation.open {
    display: block;
  }

  .navigation ul {
    flex-direction: column;
    align-items: center;
  }

  .navigation ul li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    font-family: Arial, sans-serif;	  
  }

  .navigation ul li:last-child {
    border-bottom: none;
  }
}