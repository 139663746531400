.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
}

* { font-family: arial !important }

h1 {
  margin-bottom: 30px;
  font-size: 24px;
  font-family: arial;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: arial;	
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: arial;
}

.password-input {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.forgot-password {
  text-align: right;
  margin-bottom: 20px;
}

.forgot-password button {
  background: none;
  border: none;
  color: #666;
  text-decoration: underline;
  cursor: pointer;
  font-family: arial;	
}

.sign-in-button {
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.register-prompt {
  margin-top: 20px;
}

.register-prompt button {
  background: none;
  border: none;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  font-family: arial;	
}

.error-message {
  color: red;
  margin-bottom: 10px;
  font-family: arial;	
}