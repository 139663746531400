.chat-widget {
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 350px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.chat-header {
  background-color: #7C3AF7;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.chat-header h2 {
  margin: 0;
  font-size: 16px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  opacity: 0.8;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
}

.message {
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  line-height: 1.4;
}

.message.user {
  background-color: #7C3AF7;
  color: white;
  align-self: flex-end;
}

.message.assistant {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.chat-input {
  padding: 15px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  gap: 10px;
  background-color: white;
}

.chat-input input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
}

.chat-input input:focus {
  outline: none;
  border-color: #7C3AF7;
}

.send-button {
  width: 40px;
  height: 40px;
  background-color: #7C3AF7;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
}

.send-button:hover {
  background-color: #6B32D1;
}

.send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 8px 12px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background-color: #7C3AF7;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}

.error-message {
  color: #dc3545;
  padding: 8px;
  margin: 8px 0;
  border-radius: 4px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  text-align: center;
}