.bot-management-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.bot-management-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.bot-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.bot-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.bot-card span {
  font-size: 18px;
  margin-bottom: 15px;
}

.bot-action {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  padding: 5px;
  margin: 2px 0;
}

.create-new-button {
  background-color: #333;
  color: white;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* Chat Widget Styles */
.chat-widget {
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.chat-header {
  background-color: #7C3AF7;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header h3 {
  margin: 0;
  font-size: 18px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  line-height: 1.4;
}

.bot-message {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.user-message {
  background-color: #7C3AF7;
  color: white;
  align-self: flex-end;
}

.chat-input {
  padding: 15px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  gap: 10px;
}

.chat-input textarea {
  flex: 1;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  resize: none;
  font-family: inherit;
  font-size: 14px;
}

.chat-input textarea:focus {
  outline: none;
  border-color: #7C3AF7;
}

.chat-input button {
  padding: 8px 15px;
  background-color: #7C3AF7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.chat-input button:hover:not(:disabled) {
  background-color: #6B32D1;
}

.chat-input button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Typing indicator */
.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 4px 8px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #999;
  border-radius: 50%;
  animation: bounce 1.3s linear infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.15s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}

/* Modal Styles */
.ReactModal__Content {
  width: 620px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden !important;	
}

.ReactModal__Content h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.ReactModal__Content form input,
.ReactModal__Content form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ReactModal__Content form button {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;	
}

.ReactModal__Content button.cancel {
  background-color: grey !important;
}

/* Share Modal Styles */
.share-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0;
  border-radius: 8px;
  outline: none;
  width: 620px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.share-modal-content {
  width: 100%;
}

.share-modal-header {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.share-modal-header h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #333;
}

.share-modal-body {
  padding: 20px;
}

.code-container {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 15px;
  margin: 15px 0;
  overflow-x: auto;
}

.code-container pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.code-container code {
  font-family: monospace;
  font-size: 14px;
  color: #333;
}

.copy-button {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.copy-button:hover {
  background-color: #444;
}

.copy-button.success {
  background-color: #4CAF50;
}

@media (max-width: 768px) {
  .ReactModal__Content,
  .share-modal {
    width: 320px !important;
  }
}